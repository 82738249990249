"use client"

import { useState } from "react"
import { Loader2 } from "lucide-react"

type Resource = {
  name: string
  description: string
  url: string
  freeLimits?: string
}

type CategorizedResources = {
  freeApis: Resource[]
}

export default function Bagpack() {
  const [resources, setResources] = useState<CategorizedResources | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [query, setQuery] = useState("")

  const fetchResources = async () => {
    if (!query.trim()) return

    setIsLoading(true)
    setError(null)

    try {
      const apiKey = process.env.REACT_APP_GEMINI_API_KEY
      if (!apiKey) {
        throw new Error("API key is not set")
      }

      const prompt = `
        I am building a startup based on the idea: "${query}".  
        Suggest **30 most useful free APIs** that I can use for this project.  

        Format the response as a **JSON object** with the key:  
        - "freeApis"  

        Each item should be an object with these properties:  
        - "name": The name of the API.  
        - "description": A brief explanation of what it does.  
        - "url": A direct link to its official website or documentation.  
        - "freeLimits": Details about the free-tier limitations.  

        Do **not** include any extra text, explanations, or markdown formatting—only return a valid JSON object.
      `

      const response = await fetch(
        `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=${apiKey}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            contents: [{ parts: [{ text: prompt }] }],
          }),
        },
      )

      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`)
      }

      const data = await response.json()
      let resourcesText = data.candidates[0]?.content?.parts[0]?.text || ""
      resourcesText = resourcesText.replace(/```json|```/g, "").trim()
      const parsedResources: CategorizedResources = JSON.parse(resourcesText)
      setResources(parsedResources)
    } catch (error) {
      console.error("Error fetching resources:", error)
      setError("Failed to fetch resources. Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-blue-50 to-white">
      {/* Header */}
      <header className="w-full bg-blue-500 py-2 text-center text-sm text-white">
        Find high-quality free APIs for your startup 🚀
      </header>

      {/* Main Content */}
      <main className="flex-grow flex flex-col items-center w-full max-w-4xl mx-auto px-4 py-8 sm:py-12">
        <h1 className="text-4xl sm:text-5xl font-bold text-gray-800 mb-3 text-center font-abcc">Bagpack</h1>
        <p className="text-base sm:text-lg text-gray-600 mb-6 text-center font-abcc">
          Get <span className="text-blue-600 font-semibold">free</span> APIs for your next product completely{" "}
          <span className="text-blue-600 font-semibold">free</span>
        </p>

        {/* Search Bar */}
        <div className="flex flex-col sm:flex-row bg-white shadow-md rounded-lg p-4 w-full max-w-2xl mb-8">
          <input
            type="text"
            className="flex-grow text-lg outline-none text-gray-800 mb-4 sm:mb-0 sm:mr-4"
            placeholder="Enter your startup idea..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button
            className="bg-blue-600 text-white px-5 py-2 rounded-lg font-medium hover:bg-blue-700 transition flex items-center justify-center"
            onClick={fetchResources}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="flex items-center">
                <Loader2 className="h-5 w-5 animate-spin mr-2" />
                Searching...
              </div>
            ) : (
              "Search"
            )}
          </button>
        </div>

        {/* Results Section */}
        <div className="w-full">
          {isLoading ? (
            <div className="flex justify-center items-center h-32">
              <Loader2 className="h-10 w-10 animate-spin text-blue-600" />
            </div>
          ) : error ? (
            <div className="text-red-500 text-center">{error}</div>
          ) : resources ? (
            <div className="space-y-6">
              <h2 className="text-2xl font-semibold text-gray-700 border-b pb-2">Top Free APIs</h2>
              {resources.freeApis.map((api, index) => (
                <div key={index} className="py-4 border-b border-gray-200">
                  <h3 className="text-lg font-semibold text-blue-600">
                    <a href={api.url} target="_blank" rel="noopener noreferrer" className="hover:underline">
                      {api.name}
                    </a>
                  </h3>
                  <p className="text-gray-700 text-sm">{api.description}</p>
                  {api.freeLimits && (
                    <p className="text-xs text-gray-500 mt-1">
                      <span className="font-semibold">Free Limit:</span> {api.freeLimits}
                    </p>
                  )}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </main>

      {/* Footer */}
      <footer className="w-full bg-gray-50 border-t border-gray-200 py-4 text-center text-sm text-gray-500">
        <div className="max-w-7xl mx-auto">
          <p className="text-gray-400 text-xs font-abcc">
            A product by <span className="font-semibold font-abc">StatAI</span>
          </p>
        </div>
      </footer>
    </div>
  )
}

