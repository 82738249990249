"use client"

import type React from "react"
import { useState, useEffect } from "react"
import { Loader2, ArrowRight, Star, Shield, TrendingUp, BarChart2, Users, Compass, Download } from "lucide-react"
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, BlobProvider } from "@react-pdf/renderer"
import { createClient } from "@supabase/supabase-js"
import type { User } from "@supabase/supabase-js"
import { useNavigate, Link } from "react-router-dom"
import ReactCountryFlag from "react-country-flag"

// Initialize Supabase client
const supabaseUrl = "https://pvignuinubrjactbyfzw.supabase.co"
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InB2aWdudWludWJyamFjdGJ5Znp3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzA4MTUwMzUsImV4cCI6MjA0NjM5MTAzNX0.nr6dWbfa32NDGF9vAGBhSr9MDBEjsn9KdHY9mf1cwPo"
const supabase = createClient(supabaseUrl, supabaseAnonKey)

type FormData = {
  ideaDescription: string
  targetMarket: string
  businessModel: string
  uniqueSellingPoint: string
}

const initialFormData: FormData = {
  ideaDescription: "",
  targetMarket: "",
  businessModel: "",
  uniqueSellingPoint: "",
}

// Define styles for PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 40,
  },
  section: {
    marginBottom: 15,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  content: {
    fontSize: 12,
    lineHeight: 1.5,
    marginBottom: 3,
  },
  watermark: {
    position: "absolute",
    opacity: 0.1,
    fontSize: 100,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(-45deg)",
  },
})

// PDF Document component
const MyDocument: React.FC<{ analysis: string }> = ({ analysis }) => {
  const formatContent = (text: string) => {
    return text.split("\n\n").map((section, index) => {
      const lines = section.split("\n").map((line) => line.replace(/^[#*\s]+|(\*\*)/g, "").trim())
      const title = lines[0]
      const content = lines.slice(1)
      return (
        <View key={index} style={styles.section}>
          <Text style={styles.sectionTitle}>{title}</Text>
          {content.map((item, idx) => (
            <Text key={idx} style={styles.content}>
              • {item}
            </Text>
          ))}
        </View>
      )
    })
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.watermark}>
          <Text>StatAI</Text>
        </View>
        <Text style={styles.title}>Startup Analysis</Text>
        {formatContent(analysis)}
      </Page>
    </Document>
  )
}

const languageOptions = [
  { code: "en", name: "English", flag: "GB" },
  { code: "hi", name: "Hindi", flag: "IN" },
  { code: "zh", name: "Chinese", flag: "CN" },
  { code: "de", name: "German", flag: "DE" },
]

export default function StartupIdeaAnalyzer() {
  const [step, setStep] = useState(1)
  const navigate = useNavigate()
  const [formData, setFormData] = useState<FormData>(initialFormData)
  const [analysis, setAnalysis] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [apiKey, setApiKey] = useState<string | null>(null)
  const [user, setUser] = useState<User | null>(null)
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null)

  useEffect(() => {
    const key = process.env.REACT_APP_GEMINI_API_KEY
    if (key) {
      setApiKey(key)
    } else {
      console.error("API key is not set in environment variables")
      setError("API key is not configured. Please check your environment setup.")
    }

    // Check for existing session
    const checkSession = async () => {
      const { data, error } = await supabase.auth.getSession()
      if (error) {
        console.error("Error fetching session:", error)
        return
      }
      if (data.session?.user) {
        setUser(data.session.user)
      }
    }
    checkSession()

    // Listen for auth state changes
    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session?.user) {
        setUser(session.user)
      } else {
        setUser(null)
      }
    })

    // Cleanup listener
    return () => {
      authListener.subscription.unsubscribe()
    }
  }, [])

  const logAnalyticsEvent = (event: string, params?: { [key: string]: any }) => {
    if (window.gtag) {
      window.gtag("event", event, params)
    } else {
      console.error("gtag is not available for tracking events")
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
    setError(null)
  }

  const validateStep = () => {
    switch (step) {
      case 1:
        if (formData.ideaDescription.length < 50) {
          setError("Please provide a more detailed description (at least 50 characters).")
          return false
        }
        break
      case 2:
        if (formData.targetMarket.length < 20) {
          setError("Please provide more information about your target market (at least 20 characters).")
          return false
        }
        break
      case 3:
        if (!formData.businessModel) {
          setError("Please select a business model.")
          return false
        }
        if (formData.uniqueSellingPoint.length < 20) {
          setError("Please provide a more detailed unique selling point (at least 20 characters).")
          return false
        }
        break
      case 4:
        if (!selectedLanguage) {
          setError("Please select a language for the analysis.")
          return false
        }
        break
    }
    return true
  }

  const handleNext = () => {
    if (validateStep()) {
      if (step < 4) {
        logAnalyticsEvent("step_navigation", { step: step, action: "next" })
        setStep((prevStep) => prevStep + 1)
      } else if (step === 4) {
        const form = document.querySelector("form")
        if (form) {
          form.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }))
        }
      }
    }
  }

  const handlePrevious = () => {
    logAnalyticsEvent("step_navigation", { step: step, action: "previous" })
    setStep((prevStep) => prevStep - 1)
    setError(null)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (validateStep()) {
      setIsLoading(true)
      setError(null)
      try {
        if (!apiKey) {
          throw new Error("API key is not set")
        }

        const prompt = `Analyze the following startup idea and provide the analysis in ${selectedLanguage}:
        Idea Description: ${formData.ideaDescription}
        Target Market: ${formData.targetMarket}
        Business Model: ${formData.businessModel}
        Unique Selling Point: ${formData.uniqueSellingPoint}

        Provide a detailed analysis including:
        1. Market Size and Growth Potential
        2. Competitor Analysis
        3. Unique Value Proposition
        4. Potential Risks and Challenges
        5. Next Steps and Recommendations`

        const response = await fetch(
          `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=${apiKey}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              contents: [{ parts: [{ text: prompt }] }],
            }),
          },
        )

        if (!response.ok) {
          throw new Error(`API call failed with status: ${response.status}`)
        }

        const data = await response.json()
        setAnalysis(data.candidates[0].content.parts[0].text)
        setStep(5)
      } catch (error) {
        console.error("API call error:", error)
        setError("An error occurred while generating the analysis. Please try again later.")
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleLogin = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: "google",
      })
      if (error) throw error
    } catch (error) {
      console.error("Error logging in:", error)
      setError("Failed to log in. Please try again.")
    }
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="ideaDescription" className="block text-sm font-medium text-gray-700">
                Describe your startup idea
              </label>
              <textarea
                id="ideaDescription"
                name="ideaDescription"
                value={formData.ideaDescription}
                onChange={handleInputChange}
                placeholder="Enter a brief description of your startup idea (min. 50 characters)"
                required
                className="w-full h-24 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
        )
      case 2:
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="targetMarket" className="block text-sm font-medium text-gray-700">
                Target Market
              </label>
              <input
                id="targetMarket"
                name="targetMarket"
                value={formData.targetMarket}
                onChange={handleInputChange}
                placeholder="Describe your target market (min. 20 characters)"
                required
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
        )
      case 3:
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="businessModel" className="block text-sm font-medium text-gray-700">
                Business Model
              </label>
              <select
                id="businessModel"
                name="businessModel"
                value={formData.businessModel}
                onChange={handleInputChange}
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
              >
                <option value="">Select your business model</option>
                <option value="SaaS">SaaS</option>
                <option value="E-commerce">E-commerce</option>
                <option value="Marketplace">Marketplace</option>
                <option value="Subscription">Subscription</option>
                <option value="Freemium">Freemium</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="space-y-2">
              <label htmlFor="uniqueSellingPoint" className="block text-sm font-medium text-gray-700">
                Unique Selling Point
              </label>
              <input
                id="uniqueSellingPoint"
                name="uniqueSellingPoint"
                value={formData.uniqueSellingPoint}
                onChange={handleInputChange}
                placeholder="What makes your idea unique? (min. 20 characters)"
                required
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
        )
      case 4:
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Select Language for Analysis</label>
              <div className="grid grid-cols-2 gap-2 sm:gap-4">
                {languageOptions.map((lang) => (
                  <button
                    key={lang.code}
                    type="button"
                    onClick={() => setSelectedLanguage(lang.code)}
                    className={`px-2 py-1.5 sm:px-4 sm:py-2 rounded-md text-xs sm:text-sm font-medium transition-colors duration-200 flex items-center justify-center ${
                      selectedLanguage === lang.code
                        ? "bg-blue-600 text-white"
                        : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                    }`}
                  >
                    <ReactCountryFlag
                      countryCode={lang.flag}
                      svg
                      style={{
                        width: "1.2em",
                        height: "1.2em",
                        marginRight: "0.3em",
                      }}
                    />
                    {lang.name}
                  </button>
                ))}
              </div>
            </div>
            {error && <p className="text-red-600 text-sm">{error}</p>}
          </div>
        )
      case 5:
        return (
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg max-h-screen overflow-y-auto w-full">
            {analysis && (
              <div className="space-y-6">
                {analysis.split("\n\n").map((section, index) => {
                  const lines = section.split("\n").map((line) => line.replace(/^[#*\s]+|(\*\*)/g, "").trim())
                  const title = lines[0]
                  const content = lines.slice(1)
                  return (
                    <div key={index} className="border-b border-gray-200 pb-5 last:border-b-0">
                      <h4 className="text-base font-semibold text-gray-800 mb-2">{title}</h4>
                      <ul className="space-y-3">
                        {content.map((item, idx) => (
                          <li key={idx} className="flex items-start">
                            <div className="h-2 w-2 bg-blue-500 rounded-full mr-3 mt-1.5 flex-shrink-0"></div>
                            <span className="text-gray-700 text-sm sm:text-base leading-relaxed">{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                })}
                <BlobProvider document={<MyDocument analysis={analysis} />}>
                  {({ url, loading, error }) => (
                    <PDFDownloadLink
                      document={<MyDocument analysis={analysis} />}
                      fileName="StatAI_Startup_Analysis.pdf"
                      className="mt-8 w-full px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center justify-center transition duration-150 ease-in-out shadow-sm"
                      onClick={() => logAnalyticsEvent("download_action", { action: "download_analysis" })}
                    >
                      {loading ? (
                        "Generating PDF..."
                      ) : (
                        <>
                          <Download className="mr-2 h-5 w-5" />
                          Download Analysis
                        </>
                      )}
                    </PDFDownloadLink>
                  )}
                </BlobProvider>
              </div>
            )}
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white flex flex-col">
      <div className="w-full bg-blue-500 py-1.5 text-center text-sm text-white">
        StatAI is free and supports multi-language 🌎
      </div>
      <nav className="bg-white shadow-sm py-2">
        <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-12">
            <span className="text-xl sm:text-2xl font-bold text-blue-600 font-abc">StatAI</span>
            <div className="flex items-center space-x-2 sm:space-x-4">
              <Link
                to="/bagpack"
                className="text-xs sm:text-sm font-medium text-gray-700 px-2 py-1.5 sm:px-3 sm:py-2 rounded-md transition-all duration-300 hover:bg-blue-100 hover:text-blue-600 hover:shadow-md"
              >
                Bagpack
              </Link>
              {user ? (
                <button className="px-3 py-1.5 sm:px-4 sm:py-2 text-xs sm:text-sm bg-gradient-to-r from-blue-600 to-blue-700 text-white rounded-md hover:from-blue-700 hover:to-blue-800 transform hover:scale-105 transition-all duration-300 shadow-md hover:shadow-lg">
                  V2 Coming Soon
                </button>
              ) : (
                <button
                  onClick={handleLogin}
                  className="flex items-center gap-1 sm:gap-2 bg-white text-[#042B3D] px-2 py-1.5 sm:px-4 sm:py-2 rounded-lg text-xs sm:text-sm font-medium hover:bg-gray-100 transition-colors duration-200"
                >
                  <svg className="w-4 h-4 sm:w-5 sm:h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                      fill="#4285F4"
                    />
                    <path
                      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                      fill="#EA4335"
                    />
                  </svg>
                  Login
                </button>
              )}
            </div>
          </div>
        </div>
      </nav>
      <div className="py-4 sm:py-6 font-sans">
        <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8 text-center">
          <h2 className="text-2xl sm:text-4xl font-semibold mb-2 mt-3 text-gray-800 font-abcc">
            Validate Your Startup Idea in <span className="text-blue-600">minutes!</span>
          </h2>
          <p className="text-lg sm:text-xl font-medium text-gray-600 mb-4">
            Get AI-powered insights and saves $1000s on market research—completely free
          </p>
          <div className="flex flex-col items-center justify-center space-y-1 sm:space-y-2">
            <p className="text-xs sm:text-sm text-gray-800 font-semibold">✓ No credit card required</p>
            <p className="text-xs sm:text-sm text-gray-800 font-semibold">✓ No login is required</p>
          </div>
        </div>
      </div>
      <main className="flex-grow flex flex-col items-center justify-center py-6 sm:py-12 px-3 sm:px-6 lg:px-8">
        <div className="w-full max-w-4xl">
          <div className="bg-white shadow rounded-lg p-4 sm:p-6">
            <h2 className="text-xl sm:text-2xl font-bold text-center text-gray-800 mb-4 sm:mb-6 font-abcc">
              Smart Analyzer by <span className="text-semibold">StatAI</span>
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <div className="relative pt-1">
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                    <div
                      style={{ width: `${(step / 5) * 100}%` }}
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
                    ></div>
                  </div>
                </div>
                <div className="text-sm text-gray-600 text-center">Step {step} of 5</div>
              </div>
              {error && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
                  role="alert"
                >
                  <strong className="font-bold">Error: </strong>
                  <span className="block sm:inline">{error}</span>
                </div>
              )}
              {renderStep()}
              <div className="mt-6 flex justify-between">
                {step > 1 && step < 5 && (
                  <button
                    type="button"
                    onClick={handlePrevious}
                    className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Previous
                  </button>
                )}
                {step < 4 ? (
                  <button
                    type="button"
                    onClick={handleNext}
                    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Next <ArrowRight className="ml-2 h-4 w-4 inline" />
                  </button>
                ) : step === 4 ? (
                  <button
                    type="button"
                    onClick={handleNext}
                    disabled={isLoading || !apiKey}
                    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                  >
                    {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin inline" /> : null}
                    Generate Analysis
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setStep(1)}
                    className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Start Over
                  </button>
                )}
              </div>
            </form>
          </div>

          <div className="mt-8 sm:mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 px-3 sm:px-0">
            <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <BarChart2 className="h-6 w-6 sm:h-8 sm:w-8 text-blue-500 mb-2" />
              <h3 className="text-base sm:text-lg font-semibold text-gray-800 mb-2 font-abcc">Market Overview</h3>
              <p className="text-xs sm:text-sm text-gray-600 font-abcc">
                Comprehensive analysis of your target market size and growth potential.
              </p>
            </div>
            <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <Users className="h-6 w-6 sm:h-8 sm:w-8 text-green-500 mb-2" />
              <h3 className="text-base sm:text-lg font-semibold text-gray-800 mb-2 font-abcc">Competitor Analysis</h3>
              <p className="text-xs sm:text-sm text-gray-600 font-abcc">
                Detailed insights into your competitors and your unique positioning.
              </p>
            </div>
            <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <Compass className="h-6 w-6 sm:h-8 sm:w-8 text-purple-500 mb-2" />
              <h3 className="text-base sm:text-lg font-semibold text-gray-800 mb-2 font-abcc">Next Building Steps</h3>
              <p className="text-xs sm:text-sm text-gray-600 font-abcc">
                Actionable recommendations to move your startup idea forward.
              </p>
            </div>
          </div>

          <div className="mt-6 sm:mt-8 flex justify-center space-x-4 sm:space-x-8 px-3 sm:px-0">
            <div className="flex items-center">
              <Star className="h-4 w-4 sm:h-5 sm:w-5 text-yellow-400 mr-1 sm:mr-2" />
              <p className="text-xs sm:text-sm text-gray-600 font-abcc">Trusted by Entrepreneurs</p>
            </div>
            <div className="flex items-center">
              <Shield className="h-4 w-4 sm:h-5 sm:w-5 text-green-500 mr-1 sm:mr-2" />
              <p className="text-xs sm:text-sm text-gray-600 font-abcc">Data-Driven Insights</p>
            </div>
            <div className="flex items-center">
              <TrendingUp className="h-4 w-4 sm:h-5 sm:w-5 text-blue-500 mr-1 sm:mr-2" />
              <p className="text-xs sm:text-sm text-gray-600 font-abcc">Accelerate Your Growth</p>
            </div>
          </div>
        </div>
      </main>
      <footer className="bg-gray-50 border-t border-gray-200 py-2 sm:py-3">
        <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8">
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="flex flex-col items-center sm:items-start">
              <p className="text-xs sm:text-sm text-gray-400 mb-1 font-abcc">
                &copy; 2024 StatAI. All rights reserved.
              </p>
            </div>
            <div className="flex items-center space-x-4 mt-2 sm:mt-0">
              <a
                href="https://www.linkedin.com/company/trystatai/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BaDKB7QYAQECR%2F9qIWjYBrw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-gray-700"
              >
                <span className="sr-only">LinkedIn</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                </svg>
              </a>
              <a
                href="https://twitter.com/gauravvpnd"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-gray-700"
              >
                <span className="sr-only">Twitter</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
